<template>
  <div>
    <div v-if="$fetchState.pending || loader">
      <!-- Banner -->
      <div role="status" class="max-w-6xl sm:px-6 lg:px-8 xl:px-0 px-4 mx-auto space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
        <div class="w-full">
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
          <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        </div>
        <div class="flex items-center justify-center w-full h-96 bg-gray-300 rounded sm:w-3/5 dark:bg-gray-700">
          <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
        </div>
        <span class="sr-only">Loading...</span>
      </div>

      <!-- Advisory -->
      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4">
        <div class="mt-12 space-y-4">
          <!-- <router-link :to="localePath('/training')" class="font-semibold text-black text-2xl">
            {{ $t('trading-module') }}
          </router-link> -->
          <div class="flex justify-between gap-3 overflow-x-scroll">
            <div v-for="item in 4" :key="item" role="status" class="w-72 p-2 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg class="w-12 h-12 text-gray-200 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Agri-market -->
      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4 mb-6">
        <div class="mt-12 space-y-4">
          <!-- <router-link :to="localePath('/agri-finance')" class="font-semibold text-black text-2xl">
            {{ $t('agri-finance') }}
          </router-link> -->
          <div class="flex justify-between gap-3 overflow-x-scroll">
            <div v-for="item in 5" :key="item" role="status" class="w-64 h-24 flex gap-3 p-2 border border-gray-200 rounded shadow animate-pulse md:p-4 dark:border-gray-700">
              <div class="flex items-center justify-center h-16 w-16 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg class="w-12 h-12 text-gray-200 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
              </div>
              <div>
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="$fetchState.error">An error occurred</div>
    <div v-else>
    <div>

      <!-- <div class="grid lg:grid-cols-2 grid-cols-1">
        <div class="sm:h-[700px] h-[450px]">
          <img class="w-full h-full object-cover" src="../../assets/images/image-1.png" alt="Image">
        </div>
        <div class="flex flex-col justify-center sm:p-24 p-4">
          <div class="flex gap-1">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8137 18.9957C11.8013 19.5344 11.3011 19.925 10.8009 19.8346C11.4874 19.9594 13.0851 20.1341 13.9839 19.8346C13.463 19.9127 12.9877 19.518 12.9753 18.9916C12.9753 19.0204 12.6239 16.2284 14.0294 13.646C15.2323 11.4379 15.902 11.6682 17.3116 11.1336C19.118 10.4469 20.9741 9.73963 22.2473 8.19763C24.3265 5.67698 23.7767 2.2229 23.599 1.28948C21.3668 4.47628 19.1056 3.57164 16.8279 5.02317C14.513 6.49938 13.4796 8.82677 13.7028 11.3186C15.997 7.33 18.8948 9.08582 21.8876 5.09719C21.1601 6.29789 20.1638 7.34234 18.9237 8.08661C17.1421 9.17218 16.2823 8.85555 13.5829 12.7537C13.0869 13.4692 12.6859 14.2505 12.3965 15.0688C12.0617 14.123 11.578 13.2143 10.9663 12.4165C9.73853 10.8252 9.19701 9.92467 8.12223 9.15573C6.65062 8.09483 4.14556 7.68774 2.12416 4.34058C5.4973 8.84321 8.65962 6.78722 11.2308 11.2652C11.4871 8.4279 10.2801 5.87435 7.75433 4.26245C5.22034 2.64644 2.70701 3.65388 0.22263 0.105225C0.02421 1.14556 -0.587584 4.99027 1.72732 7.79466C3.1404 9.50766 5.19401 10.2895 7.19658 11.0518L7.21694 11.0596C8.18011 11.4255 10.6562 11.5366 11.6235 15.9241C11.8881 17.1412 11.8426 17.8444 11.8137 18.9957Z" fill="#1565C0"/>
            </svg>
            <h4 class="font-bold tracking-widest">{{ $t('green-finance') }}</h4>
          </div>
          <h1 class="text-6xl font-extrabold mt-5"><span class="text-[#169D23]">{{ $t('agri-finance') }}</span></h1>
          <p class="text-2xl font-normal text-gray-600 mt-5">{{ $t('info-about-platform') }}</p>
          <div class="flex mt-12 gap-10">
            <a class="flex items-center gap-2 text-gray-600 text-lg font-semibold" href="">
              <span>{{ $t('see-how-it-works') }}</span>
              <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 8.50005C0 8.98514 0.393178 9.37832 0.878268 9.37832H17.0282L11.4879 14.9134C11.3194 15.082 11.2326 15.3066 11.2326 15.5313C11.2326 15.756 11.3194 15.9806 11.4879 16.1491C11.8301 16.4913 12.3866 16.4913 12.7288 16.1491L19.7549 9.1179C20.0817 8.7911 20.0817 8.20899 19.7549 7.87709L12.7237 0.850947C12.3815 0.508831 11.825 0.508831 11.4828 0.850947C11.1407 1.19306 11.1407 1.74964 11.4828 2.09176L17.018 7.62689H0.873161C0.388071 7.62178 0 8.01496 0 8.50005Z" fill="#676767"/>
              </svg>
            </a>
          </div>
        </div>
      </div> -->

      <div class="bg-gradient-to-r from-green-100 to-green-50 relative pt-2">
        <swiper
          ref="swiper"
          class="swiper custom-style max-w-6xl"
          :options="sliderOption"
        >
          <client-only>
            <swiper-slide v-for="(slide, index) in slides" :key="index">
              <div class="mx-auto lg:px-8 xl:px-0">
                <div class="grid lg:grid-cols-2 grid-cols-1 px-0 items-center">
                  <div class="grid content-center px-4">
                    <!-- <div class="flex items-center my-5">

                      <img width="20" height="18" :src="'https://bdp.agsat.uz/uploads' + slide.attributes?.logo" alt="Logo images" />
                      <div class="text-gray-800 text-base font-semibold ml-1">
                        {{ slide.attributes?.subtitle }}
                      </div>
                    </div> -->
                    <div class="lg:text-5xl mb-3 text-2xl font-semibold">
                      <!-- {{ $t('agriculture-business') }}<br /> -->
                      {{ slide.attributes?.title }}
                      <!-- <div class="text-green-800 lg:my-4 my-0">{{ $t('in-karakalpakstan') }}</div> -->
                    </div>
                    <br>
                    <div class="text-gray-500 mr-4">
                      <!-- {{ $t('info-about-platform') }} -->
                      {{ slide.attributes?.description }}
                    </div>
                    <!-- <div class="my-5">
                      <button
                        v-if="index == 0"
                        @click="openRegister()"
                        class="text-white text-sm bg-green-700 focus:outline-none rounded-md py-3 px-5"
                      >
                        {{ $t('register') }}
                      </button>
                      <div v-else class="flex gap-4 md:justify-start justify-center">
                        <div class="flex-custom gap-4 my-4 social-btns md:flex-nowrap flex-wrap">
                          <a v-if="slide.attributes.play_market_link" :href="slide.attributes.play_market_link" class="w-44 rounded-md overflow-hidden h-14" target="_blank"><img class="w-full object-cover h-full" src="~/assets/images/playmarket.png" alt="Play market"></a>
                          <a v-if="slide.attributes.appstore_link" :href="slide.attributes.appstore_link" class="w-44 rounded-md overflow-hidden h-14" target="_blank"><img class="w-full object-cover h-full" src="~/assets/images/appstore.jpg" alt="App Store"></a>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="">
                    <img :src="'https://api-greenf.calypso.uz/uploads' + slide.attributes?.image" class="rounded-md" alt="home" />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </client-only>
        </swiper>
        <!-- <div class="absolute group top-1/2 lg:flex hidden justify-between -inset-x-9">
          <div
            slot="button-prev"
            class="swiper-button-prev btn shadow-md opacity-0 left-9"
            @click="prev()"
          />
          <div
            slot="button-next"
            class="swiper-button-next btn shadow-md opacity-0 -right-9"
            @click="next()"
          />
        </div> -->
      </div>

      <div class="max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4">

        <div class="mt-12 space-y-4">
          <router-link :to="localePath('/training')" class="font-Nunito text-black text-2xl">
            <span class="text-black"> {{ $t('e-learning') }}</span>
          </router-link>
          <div class="grid md:grid-cols-3 md:gap-6 gap-0 sm:grid-cols-2 grid-cols-1">
            <div v-for="(video, index) in dataCourses" :key="index" class="mt-6">
              <video-card :data="video" />
            </div>
          </div>
          <router-link
            :to="localePath('/training')"
            class="flex items-center my-6"
          >
            <button class="text-[#169D23] focus:outline-none text-sm flex items-center">
              {{ $t('see-all') }}
              <i class="bx bx-right-arrow-alt text-[#169D23] text-xl ml-1"></i>
            </button>
          </router-link>
        </div>
        <!-- <div class="mt-12 space-y-4">
          <div class="grid lg:grid-cols-2 grid-cols-1 gap-6 lg:pl-[40px]">
            <div class="sm:w-96 w-72 sm:h-[600px] h-[450px] relative lg:m-0 mx-auto before-card">
              <img class="w-full h-full object-cover" src="../../assets/images/image-2.png" alt="Image of About us section">
            </div>
            <div>
              <h3 class="text-lg font-normal">About Us</h3>
              <h2 class="text-5xl mt-7 font-bold">{{ $t('internet-platform-facilitating') }}</h2>
              <p class="mt-7 font-normal">{{ $t('about-us-banner-text') }}</p>
              <button @click="() => $router.push({path: localePath('/about')})"
                class="text-white text-sm bg-[#169D23] focus:outline-none rounded-md py-3 px-5 mt-7"
              >
                {{ $t('more') }}
              </button>
            </div>
          </div>
        </div> -->
        <div class="mt-12 space-y-4">
          <router-link :to="localePath('/agri-finance')" class="font-semibold text-black text-2xl">
            <span class="text-black">{{ $t('agri-finance') }}</span>
          </router-link>
          <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            <NewsSwiper :news="serviceposts" class="col-span-3" />
          </div>
          <router-link
            :to="localePath('/agri-finance')"
            class="flex items-center my-4"
          >
            <button class="text-[#169D23] focus:outline-none text-sm flex items-center">
              {{ $t('see-all') }}
              <i class="bx bx-right-arrow-alt text-[#169D23] text-xl ml-1"></i>
            </button>
          </router-link>
        </div>
        <div class="mt-12 space-y-4">
<!--          <router-link-->
<!--            :to="localePath('/agri-market')"-->
<!--            class="font-semibold text-green-800 text-2xl"-->
<!--          >{{ $t('agri-market') }}</router-link-->
<!--          >-->
          <div class="">
            <price-swiper :prices="pricList" />
          </div>
        </div>

        <div class="mt-12 space-y-4">
          <!--        <div class="">-->
          <!--          <partners-swiper :partners="dataPartners" />-->
          <!--        </div>-->
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import registeModal from '../../components/modals/register.vue'
import expertsSwiper from '~/components/swipers/experts-swiper.vue'
import NewsSwiper from '~/components/swipers/news-swipers/new-swipers'
import PriceSwiper from '~/components/swipers/price-swiper.vue'
import PartnersSwiper from '~/components/swipers/partners-swiper.vue'
// import price1 from '~/assets/images/swaper/price-1.png'
// import price2 from '~/assets/images/swaper/price-2.png'
// import price3 from '~/assets/images/swaper/price-3.png'
import price4 from '~/assets/images/swaper/price-4.png'
import price5 from '~/assets/images/swaper/price-5.png'
import price6 from '~/assets/images/swaper/price-6.png'
import price7 from '~/assets/images/swaper/price-7.jpg'
// import price8 from '~/assets/images/swaper/price-8.jpg'
import price8 from '~/assets/images/swaper/newCompaniyaname.png'
import selxoz from '~/assets/images/swaper/selxoz.png'
// import consort from '~/assets/images/swaper/consort.jpg'
// import YandexMap from '~/components/core/yandex-map.vue'
import Faq from '~/components/Faq.vue'
import MainLoading from '~/components/main-loading'
export default {
  auth: false,
  components: {
    MainLoading,
    // YandexMap,
    expertsSwiper,
    PriceSwiper,
    NewsSwiper,
    PartnersSwiper,
    Faq,
  },
  props: {},
  data() {
    return {
      video: {},
      loading: false,
      experts: [],
      pricList: [price7, price8, selxoz],
      slides: [],
      serviceposts: [],
      settings: {
        apiKey: 'b8d77dd9-8477-4216-ad67-5074082c24a6',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1',
      },
      companyCategories: [],
      sliderOption: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 6000
        },
        pagination: {
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 6,
          },
          410: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          350: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      items: [
        {
          image: 'district',
          title: 'District Hokimiyat',
        },
        {
          image: 'bank',
          title: 'Banks',
        },
        {
          image: 'terminal',
          title: 'Terminal stations',
        },
        {
          image: 'railway',
          title: 'Railway stations',
        },
        {
          image: 'refrigerator',
          title: 'Refrigerators',
        },
        {
          image: 'government',
          title: 'Government branches',
        },
        {
          image: 'farmers',
          title: 'Farmers unions',
        },
        {
          image: 'supply',
          title: 'Supply points',
        },
      ],
      dataTitle: {
        en: 'Post',
        ru: 'Пост',
        uz: 'post',
        kaa: 'Post Kaa',
      },
      loader: false
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      currentUser: (state) => state.auth.user,
      userConnection: (state) => state.socket.userConnection,
      userConnectionStatus: (state) => state.socket.userConnectionStatus,
    }),
    ...mapGetters([
      'dataCourses',
      'dataUsers',
      'dataFaqs',
      // 'dataPartners',
      'getLocations',
      'dataNews',
    ]),
  },
  fetchOnServer: true,
  async fetch() {
    // this.$fetchState.pending = true
    await this.$store
      .dispatch('getSliders', {
        locale: this.$i18n.locale,
        fields: 'logo,subtitle,title,description,play_market_link,appstore_link,image',
        sort: 'id:desc'
      })
      .then(res => {
        this.slides = res

      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    // await this.$store
    //   .dispatch('getPricelists', {
    //     populate: 'district, district.localizations, product,product.localizations',
    //     locale: this.$i18n.locale,
    //     'pagination[page]': 1,
    //     'pagination[pageSize]': 12,
    //     filters: {
    //       max: {
    //         $gt: 0,
    //       },
    //       min: {
    //         $gt: 0,
    //       },
    //     },
    //   })
    //   .then((res) => {
    //     this.pricList = res.map((el) => {
    //       return {
    //         id: el.id,
    //         attributes: {
    //           ...el.attributes,
    //           district: {
    //             data: {
    //               attributes: {
    //                 name:
    //                   el.attributes.district.data.attributes.locale === this.$i18n.locale
    //                     ? el.attributes.district.data.attributes.name
    //                     : this.checkProductLocalizations(
    //                         el.attributes.district.data.attributes.localizations.data,
    //                         this.$i18n.locale
    //                       ),
    //               },
    //             },
    //           },
    //           product: {
    //             data: {
    //               attributes: {
    //                 image: el.attributes.product.data.attributes.image,
    //                 name:
    //                   el.attributes.product.data.attributes.locale === this.$i18n.locale
    //                     ? el.attributes.product.data.attributes.name
    //                     : this.checkProductLocalizations(
    //                         el.attributes.product.data.attributes.localizations.data,
    //                         this.$i18n.locale
    //                       ),
    //               },
    //             },
    //           },
    //         },
    //       }
    //     })
    //   })
    //   .catch((error) => {
    //     this.$sentry.captureException(error)
    //   })
    await this.$store
      .dispatch('getUsers', {
        link: '/users',
        query: {
          populate:
            'additionalinfo,additionalinfo.localization,consultantcategory, consultantcategory.localization,agrocultureareas, agrocultureareas.localization',
          locale: this.$i18n.locale,
          'filters[$and][0][confirmed]': true,
          'filters[$and][0][role][id]': 4,
        },
      })
      .then((res) => {
        this.experts = res.users
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    //

    await this.$store
      .dispatch('getFaqs', {
        populate: '*',
        locale: this.$i18n.locale,
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.$store
      .dispatch('getCourses', {
        populate: '*',
        locale: this.$i18n.locale,
        sort: ['createdAt:desc'],
        'pagination[page]': 1,
        'pagination[pageSize]': 3,
        filters: {
          status: {
            title: this.dataTitle[this.$i18n.locale],
          },
        },
      })
      .catch((error) => {
        this.$sentry.captureException(error)
      })
    await this.getPartners()
    await this.getCompanies()
    await this.getCompanycategories()
    await this.getNews()
    // await this.getAbout()

  },
  methods: {
    async getNews () {
      try {
        const data =  await this.$store
          .dispatch('getServiceposts', {
            populate: '*',
            locale: this.$i18n.locale,
            'sort[0][createdAt]': 'DESC',
          })
        this.serviceposts = data;
        return data
      }catch (err) {}
    },
    async getCompanies () {
     try {
       const data =   await this.$store
         .dispatch('getCompanies', {
           populate: '*',
           locale: this.$i18n.locale,
         })

         .catch((error) => {
           this.$sentry.captureException(error)
         })
           this.$store.dispatch('setCompanies', data)
       return data
     } catch (err) {}
    },
    async getCompanycategories () {
      try {
        const data = await this.$store
          .dispatch('getCompanycategories', {
            populate: '*',
            locale: this.$i18n.locale,
          })
          // .then((res) => {
            this.companyCategories = data
          // })
        return data
      } catch (err) {}
    },
    async getPartners () {
      try {
        const data=   await this.$store
          .dispatch('getPartners', {
            populate: '*',
            locale: this.$i18n.locale,
          })
        return data
      } catch (err)  {}
    },
    // async getAbout () {
    //   try {
    //     const data=  await this.$store
    //       .dispatch('getAbout', {
    //         locale: this.$i18n.locale
    //       })
    //       // .then(res => {
    //         console.log("getAbout", data)
    //       // })
    //       // .catch((error) => {
    //       //   this.$sentry.captureException(error)
    //       // })
    //
    //   } catch (err){
    //
    //   }
    // },
    prev() {
      this.$refs.swiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiper.$swiper.slideNext()
    },
    checkProductLocalizations(item, lang) {
      let _ = item.find((el) => el.attributes.locale === lang).attributes.name
      return _
    },
    moveToAgriBusiness(item) {
      let _query = {
        category: item.id,
      }
      this.$router.push({
        path: this.localePath('/agri-business'),
        query: this.$tools.emptyObject(_query),
      })
    },
    openRegister() {
      this.$emit('close')
      this.$modal.show(
        registeModal,
        { status: 'register' },
        {
          height: 'auto',
          maxWidth: 700,
          width: window.innerWidth <= 650 ? window.innerWidth - 10 : 650,
          scrollable: true,
        }
      )
    },
  },

}
</script>


<style>
  .flex-custom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-style > div {
    align-items: center !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    --swiper-theme-color: #059669;
    background-color: white;
    padding: 22px;
    color: #059669 !important;
    fill: #059669 !important;
    stroke: #059669 !important;
    border-radius: 100%;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 13px;
  }
  .experts:hover .btn {
    opacity: 1;
  }
  .before-card::before {
    content: "";
    position: absolute;
    width: 465px;
    height: 400px;
    background: #1565C0;
    opacity: 0.3;
    box-shadow: inset 28.3px -28.3px 28.3px rgba(149, 149, 149, 0.081), inset -28.3px 28.3px 28.3px rgba(255, 255, 255, 0.081);
    backdrop-filter: blur(26.885px);
    border-radius: 20px;
    left: -40px;
    z-index: -1;
    bottom: 0;
  }

  @media screen and (max-width: 640px) {
    .before-card::before {
      display: none;
    }
  }
</style>
