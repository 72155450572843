var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.$fetchState.pending || _vm.loader
      ? _c("div", [
          _c(
            "div",
            {
              staticClass:
                "max-w-6xl sm:px-6 lg:px-8 xl:px-0 px-4 mx-auto space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center",
              attrs: { role: "status" },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-center w-full h-96 bg-gray-300 rounded sm:w-3/5 dark:bg-gray-700",
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "w-12 h-12 text-gray-200",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        "aria-hidden": "true",
                        fill: "currentColor",
                        viewBox: "0 0 640 512",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4" },
            [
              _c("div", { staticClass: "mt-12 space-y-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "flex justify-between gap-3 overflow-x-scroll",
                  },
                  _vm._l(4, function (item) {
                    return _c(
                      "div",
                      {
                        key: item,
                        staticClass:
                          "w-72 p-2 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700",
                        attrs: { role: "status" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700",
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "w-12 h-12 text-gray-200 dark:text-gray-600",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "aria-hidden": "true",
                                  fill: "currentColor",
                                  viewBox: "0 0 640 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4 mb-6",
            },
            [
              _c("div", { staticClass: "mt-12 space-y-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "flex justify-between gap-3 overflow-x-scroll",
                  },
                  _vm._l(5, function (item) {
                    return _c(
                      "div",
                      {
                        key: item,
                        staticClass:
                          "w-64 h-24 flex gap-3 p-2 border border-gray-200 rounded shadow animate-pulse md:p-4 dark:border-gray-700",
                        attrs: { role: "status" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-center h-16 w-16 mb-4 bg-gray-300 rounded dark:bg-gray-700",
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "w-12 h-12 text-gray-200 dark:text-gray-600",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "aria-hidden": "true",
                                  fill: "currentColor",
                                  viewBox: "0 0 640 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(1, true),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
        ])
      : _vm.$fetchState.error
      ? _c("div", [_vm._v("An error occurred")])
      : _c("div", [
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "bg-gradient-to-r from-green-100 to-green-50 relative pt-2",
              },
              [
                _c(
                  "swiper",
                  {
                    ref: "swiper",
                    staticClass: "swiper custom-style max-w-6xl",
                    attrs: { options: _vm.sliderOption },
                  },
                  [
                    _c(
                      "client-only",
                      _vm._l(_vm.slides, function (slide, index) {
                        return _c("swiper-slide", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "mx-auto lg:px-8 xl:px-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "grid lg:grid-cols-2 grid-cols-1 px-0 items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "grid content-center px-4" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "lg:text-5xl mb-3 text-2xl font-semibold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(slide.attributes?.title) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-gray-500 mr-4" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                slide.attributes?.description
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {}, [
                                    _c("img", {
                                      staticClass: "rounded-md",
                                      attrs: {
                                        src:
                                          "https://api-greenf.calypso.uz/uploads" +
                                          slide.attributes?.image,
                                        alt: "home",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "max-w-6xl mx-auto sm:px-6 lg:px-8 xl:px-0 px-4" },
              [
                _c(
                  "div",
                  { staticClass: "mt-12 space-y-4" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "font-Nunito text-black text-2xl",
                        attrs: { to: _vm.localePath("/training") },
                      },
                      [
                        _c("span", { staticClass: "text-black" }, [
                          _vm._v(" " + _vm._s(_vm.$t("e-learning"))),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid md:grid-cols-3 md:gap-6 gap-0 sm:grid-cols-2 grid-cols-1",
                      },
                      _vm._l(_vm.dataCourses, function (video, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "mt-6" },
                          [_c("video-card", { attrs: { data: video } })],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "flex items-center my-6",
                        attrs: { to: _vm.localePath("/training") },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "text-[#169D23] focus:outline-none text-sm flex items-center",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("see-all")) +
                                "\n              "
                            ),
                            _c("i", {
                              staticClass:
                                "bx bx-right-arrow-alt text-[#169D23] text-xl ml-1",
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-12 space-y-4" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "font-semibold text-black text-2xl",
                        attrs: { to: _vm.localePath("/agri-finance") },
                      },
                      [
                        _c("span", { staticClass: "text-black" }, [
                          _vm._v(_vm._s(_vm.$t("agri-finance"))),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1",
                      },
                      [
                        _c("NewsSwiper", {
                          staticClass: "col-span-3",
                          attrs: { news: _vm.serviceposts },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "flex items-center my-4",
                        attrs: { to: _vm.localePath("/agri-finance") },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "text-[#169D23] focus:outline-none text-sm flex items-center",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("see-all")) +
                                "\n              "
                            ),
                            _c("i", {
                              staticClass:
                                "bx bx-right-arrow-alt text-[#169D23] text-xl ml-1",
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mt-12 space-y-4" }, [
                  _c(
                    "div",
                    {},
                    [_c("price-swiper", { attrs: { prices: _vm.pricList } })],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-12 space-y-4" }),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full" }, [
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", {
        staticClass:
          "h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-36 mb-4",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }